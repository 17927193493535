import { FirebaseFile } from "@/data/providers/files-provider";
import { ShowcaseProject } from "../showcase/showcase-types";

export class HomeState {
	public projects: Array<FirebaseFile> = [];

	public isBusy: boolean = false;

	public latestShowcaseProjectsBusy: boolean = false;

	public latestShowcaseProjects: Array<ShowcaseProject> = [];

	public userName: string = "";
}