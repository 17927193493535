/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { authentication } from "@/data/providers/authentication-provider";
import { getTranslatedText } from "@/data/providers/localization-provider";
import { reactive } from "vue";
import { ClassroomState } from "./classroom-state";

export class ClassroomModel {
	// State for Classroom View
	public state: ClassroomState = reactive(new ClassroomState());

	/**
	 * Get Translated text for the classroom view
	 * @param {string} key
	 */
	public getText(key: string): string {
		return getTranslatedText("classroom", key);
	} 

	/**
	 * Initialize the classroom view
	 */
	public init(): void {
		if (authentication.currentUser.value) {
			this.getClassrooms();
		}
	}

	public gradients: Array<string> = [
		"bg-gradient-to-b from-blue-300 to-blue-600",
		"bg-gradient-to-b from-blue-500 to-rose-500",
		"bg-gradient-to-b from-green-300 to-cyan-500",
		"bg-gradient-to-b from-amber-300 to-orange-500",
		"bg-gradient-to-b from-fuchsia-500 to-purple-600"
	]

	public getRandomGradient(): string {
		return this.gradients[Math.floor(Math.random() * this.gradients.length)];
	}

	/**
	 * Get all classrooms for current user
	 */
	public getClassrooms(): void {
		this.state.classrooms = [];
		this.state.isBusy = true;
		authentication.db.collection("classrooms").where("admins", "array-contains", authentication.currentUser.value?.uid).get().then((querySnapshot: firebase.default.firestore.QuerySnapshot) => {
			querySnapshot.forEach((doc: firebase.default.firestore.DocumentSnapshot) => {
				const data: any = doc.data();
				if (data) {
					this.state.classrooms.push({id: doc.id, data: data});
				}
			});        
		}).finally(() => {
			this.state.isBusy = false;
		});
		authentication.db.collection("classrooms").where("students", "array-contains", authentication.currentUser.value?.uid).get().then((querySnapshot: firebase.default.firestore.QuerySnapshot) => {
			querySnapshot.forEach((doc: firebase.default.firestore.DocumentSnapshot) => {
				const data: any = doc.data();
				if (data) {
					this.state.classrooms.push({id: doc.id, data: data});
				}
			});        
		}).finally(() => {
			this.state.isBusy = false;
		});
	}

	public newClassroomFormData: any = reactive({
		name: "",
	}) 

	public joinClassroomFormData: any = reactive({
		code: "",
	}) 

	public goToJoinLink(): void {
		location.href = `https://join.edublocks.org/${this.joinClassroomFormData.code}`;
	}

	public async createNewClass(): Promise<void> {
		await authentication.db.collection("classrooms").add({
			name: this.newClassroomFormData.name,
			admins: [authentication.currentUser.value?.uid],
			gradient: this.getRandomGradient(),
			students: [],
			assignments: []
		}).then(() => {
			this.getClassrooms();
			this.state.isNewClassroomModalOpen = false;
		});
	}
} 