import { state } from "@/data/providers/global-provider";
import router from "@/router";
import clb from "clb";

export class EbToolbar {
	public variants: Function = clb({
		defaults: {
			type: "basic"
		},
		variants: {
			type: {
				basic: "inline-flex items-center flex-none px-4 py-2 text-sm font-medium transition-all border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 space-x-2",
				rounded: "inline-flex items-center p-2 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all"
			},
			color: {
				pink: "bg-blue-500 hover:bg-pink-600 focus:ring-blue-500 text-white border-transparent",
				gray: "border-gray-300 focus:ring-gray-300 bg-white hover:bg-gray-100"
			}
		}
	})

	/**
	 * Sets the global filename within state to the value of the filename input.
	 * @param {EventTarget|any} event
	 * @returns void
	 */
	public onFilenameInput(event: EventTarget | any): void {
		state.filename = event.target.innerText;
	}

	public goBack(): void {
		if (state.assignmentModeActive) {
			router.back();
		}
		else {
			router.push({path: "/"});
		}
	}
}