
import { defineComponent, onMounted } from "vue";
import { ShowcaseProjectModel } from "./showcase-project-model";
import { View } from "../../constants";

export default defineComponent({
	name: View.ShowcaseProject,
	setup() {
		const view: ShowcaseProjectModel = new ShowcaseProjectModel();

		onMounted(() => {
			view.init();
		});

		return { view };
	}
});
