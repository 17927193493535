
import { defineComponent, onMounted } from "vue";
import { View } from "@/views/constants";
import { ClassroomModel } from "./classroom-model";
import { authentication } from "@/data/providers/authentication-provider";

export default defineComponent({
	name: View.Classroom,
	setup() {
		const view: ClassroomModel = new ClassroomModel();

		onMounted(() => {
			view.init();
		});

		return { view, authentication };
	}
});
