<template>
	<div class="flex h-screen overflow-hidden bg-gray-50 dark:bg-gray-900">
		<div class="flex flex-col flex-1 overflow-hidden">
			<slot />
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { defaultNavigation } from "@/data/providers/navigation-provider";

export default defineComponent({
	name: "EditorLayout",
	setup() {
		return { defaultNavigation };
	}
});
</script>
