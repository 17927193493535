
import { computed, defineComponent, ComputedRef, Ref, ref, onMounted, watchEffect, nextTick } from "vue";
import { ComponentName } from "../constants";
import { EbSearch, searchTerm } from "./eb-search";
import { SearchResult } from "./eb-search-types";
import { state } from "@/data/providers/global-provider";

export default defineComponent({
	name: ComponentName.EbSearch,
	setup() {
		const component: EbSearch = new EbSearch();
		const search: Ref = ref();

		const filteredResults: ComputedRef<Array<SearchResult>> = computed((): Array<SearchResult> => {
			let filtered: Array<SearchResult> = component.searchResults;
			if (searchTerm.value) {
				filtered = filtered.filter((result: SearchResult) => {
					return result.title.toLowerCase().includes(searchTerm.value.toLowerCase());
				});
			}
			return filtered;
		});

		onMounted(() => {
			watchEffect(() => {
				if (state.isSearchOpen) {
					nextTick(() => {
						search.value.focus();
					});
				}
			});
			component.getAllFilesFromFirebase();
		});

		return { component, filteredResults, searchTerm, state, search };
	},
});
