<template>
	<div
		class="drop-shadow-flag"
		:class="[
			'flag',
			`size-${size}`,
			{'border-radius': hasBorderRadius },
			{'border': hasBorder },
			gradient,
			className
		]"
	>
		<img :src="imageUrl">
	</div>
</template>

<script>
import { isoToCountryCode, imageUrl } from "flagpack-core";

export default {
	name: "Flag",
	props: {
		size: {
			type: String,
			default: "s",
		},
		code: {
			type: String,
			default: "528"
		},
		hasDropShadow: {
			type: Boolean,
			default: false,
		},
		hasBorder: {
			type: Boolean,
			default: false
		},
		hasBorderRadius: {
			type: Boolean,
		},
		gradient: {
			type: String,
		},
		className: {
			type: String
		}
	},
	computed: {
		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
		imageUrl () {
			// eslint-disable-next-line @typescript-eslint/typedef
			let code = this.code;

			if (code === "GB") {
				code = "GB-UKM";
			}
			if (code === "DZ") {
				code = "AFRUN";
			}
			if (code === "AC") {
				code = "SH";
			}
			if (code === "YT") {
				code = "FR";
			}
			if (code === "AN") {
				code = "NL";
			}
			if (code === "KN") {
				code = "KN-SK";
			}
			if (code === "IC") {
				code = "ES";
			}
			if (code === "IC") {
				code = "ES";
			}
			if (code === "XK") {
				code = "RS";
			}
			// eslint-disable-next-line @typescript-eslint/typedef
			const url = imageUrl(isoToCountryCode(code).toUpperCase(), this.size.toLowerCase());

			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return url.default;
		}
	},
};
</script>

<style>
.flag {
	 display: inline-block;
	 overflow: hidden;
	 position: relative;
	 box-sizing: border-box;
}
 .flag.size-s {
	 width: 12px;
	 height: 12px;
}
 .flag.size-s.drop-shadow-flag {
	 box-shadow: 0 0 1px 0.5px rgba(0, 0, 0, 0.10);
}
 .flag.size-s.border-radius {
	 border-radius: 1px;
}
 .flag.size-m {
	 width: 15px;
	 height: 15px;
}
 .drop-shadow-flag {
	 box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.10);
}
 .flag.size-m.border-radius {
	 border-radius: 1.5px;
}
 .flag.size-l {
	 width: 24px;
	 height: 24px;
}
 .flag.size-l.drop-shadow-flag {
	 box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.10);
}
 .flag.size-l.border-radius {
	 border-radius: 2px;
}
 .flag.top-down::before {
	 content: '';
	 width: 100%;
	 height: 100%;
	 position: absolute;
	 display: block;
	 mix-blend-mode: overlay;
	 box-sizing: border-box;
	 background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 2%, rgba(255, 255, 255, 0.70) 100%);
}
 .flag.real-linear::before {
	 content: '';
	 width: 100%;
	 height: 100%;
	 position: absolute;
	 display: block;
	 mix-blend-mode: overlay;
	 box-sizing: border-box;
	 background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.20) 0%, rgba(39, 39, 39, 0.22) 11%, rgba(255, 255, 255, 0.30) 27%, rgba(0, 0, 0, 0.24) 41%, rgba(0, 0, 0, 0.55) 52%, rgba(255, 255, 255, 0.26) 63%, rgba(0, 0, 0, 0.27) 74%, rgba(255, 255, 255, 0.30) 100%);
}
 .flag.real-circular::before {
	 content: '';
	 width: 100%;
	 height: 100%;
	 position: absolute;
	 display: block;
	 mix-blend-mode: overlay;
	 box-sizing: border-box;
	 background: radial-gradient(0% at 50% 36%, rgba(255, 255, 255, 0.30), rgba(0, 0, 0, 0.24) 11%, rgba(0, 0, 0, 0.55) 17%, rgba(255, 255, 255, 0.26) 22%, rgba(0, 0, 0, 0.17) 27%, rgba(255, 255, 255, 0.28) 31%, rgba(255, 255, 255, 0.00) 37%) center calc(50% - 8px) / 600% 600%, radial-gradient(25% at 50% 123%, rgba(255, 255, 255, 0.30), rgba(0, 0, 0, 0.24) 48%, rgba(0, 0, 0, 0.55) 61%, rgba(255, 255, 255, 0.26) 72%, rgba(0, 0, 0, 0.17) 80%, rgba(255, 255, 255, 0.28) 88%, rgba(255, 255, 255, 0.30) 100%) center calc(50% - 8px) / 600% 600%;
}
 .flag img {
	 display: block;
	 width: 100%;
	 height: 100%;
	 object-fit: cover;
}
 
</style>