<template>
	<div class="mb-4">
		<div class="border-b border-gray-200 dark:border-gray-800">
			<nav
				v-if="items"
				class="flex -mb-px"
			>
				<a
					v-for="tab in items"
					v-show="tab.visible"
					:key="tab"
					class="px-1 py-4 mr-8 text-sm font-medium transition-all border-b-2 whitespace-nowrap"
					:class="selectedTab === tab.key ? 'text-blue-500 border-blue-500' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 cursor-pointer dark:border-gray-800 dark:text-gray-400 dark:hover:text-gray-500 dark:hover:border-gray-600'"
					@click="tab.action(); selectedTab = tab.key"
				>
					{{ tab.title }}
					<span
						v-if="tab.count"
						:class="selectedTab === tab.key ? 'bg-blue-50 text-blue-500 dark:bg-opacity-20' : 'bg-gray-100 text-gray-900 dark:bg-opacity-20 dark:text-gray-300'"
						class="ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
					>
						{{ tab.count }}
					</span>
				</a>
			</nav>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, watchEffect } from "vue";
import { ComponentName } from "../constants";
import { selectedTab, Tab } from "./eb-selector";
import { Data } from "../types";

export default defineComponent({
	name: ComponentName.EbToolbar,
	props: {
		items: {
			type: Object as () => Tab
		},
		selected: {
			type: String
		}
	},
	setup(props: Data) {
		watchEffect(() => {
			selectedTab.value = props.selected as string;
		});
		
		return { selectedTab };
	}
});
</script>
