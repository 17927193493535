<template>
	<transition
		enter-active-class="transition duration-300 ease-out"
		enter-from-class="opacity-0"
		enter-to-class="opacity-100"
		leave-active-class="transition duration-150 ease-in"
		leave-from-class="opacity-100"
		leave-to-class="opacity-0"
	>
		<div
			v-if="state.isSearchOpen"
			class="absolute inset-0 z-40 w-screen h-screen transition-opacity bg-gray-800 cursor-pointer bg-opacity-60"
			@click="state.isSearchOpen = false; searchTerm = ''"
		/>
	</transition>

	<transition
		enter-active-class="transition duration-300 ease-out"
		enter-from-class="transform scale-95 opacity-0"
		enter-to-class="transform scale-100 opacity-100"
		leave-active-class="transition duration-150 ease-in"
		leave-from-class="transform scale-100 opacity-100"
		leave-to-class="transform scale-95 opacity-0"
	>
		<div
			v-if="state.isSearchOpen"
			class="absolute top-0 z-50 flex justify-center w-full h-full pointer-events-none"
		>
			<div class="w-full max-w-lg px-4 pt-16 transition-all transform sm:pt-24">
				<div class="overflow-hidden bg-white rounded-lg shadow-md pointer-events-auto">
					<form class="relative flex items-center pr-4 shadow-sm">
						<input
							ref="search"
							v-model="searchTerm"
							class="flex-auto py-4 pl-4 pr-12 text-base text-gray-600 placeholder-gray-500 bg-transparent appearance-none -mr-9 sm:text-sm focus:outline-none"
							:placeholder="component.getText('search-for-anything')"
						>
						<SearchIcon class="w-[20px] h-[20px] flex-none text-gray-400 pointer-events-none" />
					</form>
					<ul
						v-if="searchTerm"
						class="text-sm font-medium overflow-y-auto border-t border-gray-200 divide-y divide-gray-200 rounded-b-lg max-h-[18.375rem]"
					>
						<li
							v-for="result in filteredResults"
							:key="result"
							tabindex="-1"
							@click="result.action(); searchTerm = '';"
						>
							<a
								class="flex justify-between p-4 transition-all cursor-pointer hover:bg-gray-50 group"
							>
								<span class="text-gray-900 transition-colors group-hover:text-blue-500 whitespace-nowrap">{{ result.title }}</span>
								<span class="ml-4 text-right text-gray-500">{{ result.subtitle }}</span>
							</a>
						</li>
					</ul>
					<div
						class="p-16 text-center bg-gray-50"
						:class="filteredResults.length === 0 ? 'block' : 'hidden'"
					>
						<h2 class="mb-2 font-semibold text-gray-900">
							{{ component.getText('no-results') }}
						</h2>
						<p class="text-sm">
							{{ component.getText('no-results-description') }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script lang="ts">
import { computed, defineComponent, ComputedRef, Ref, ref, onMounted, watchEffect, nextTick } from "vue";
import { ComponentName } from "../constants";
import { EbSearch, searchTerm } from "./eb-search";
import { SearchResult } from "./eb-search-types";
import { state } from "@/data/providers/global-provider";

export default defineComponent({
	name: ComponentName.EbSearch,
	setup() {
		const component: EbSearch = new EbSearch();
		const search: Ref = ref();

		const filteredResults: ComputedRef<Array<SearchResult>> = computed((): Array<SearchResult> => {
			let filtered: Array<SearchResult> = component.searchResults;
			if (searchTerm.value) {
				filtered = filtered.filter((result: SearchResult) => {
					return result.title.toLowerCase().includes(searchTerm.value.toLowerCase());
				});
			}
			return filtered;
		});

		onMounted(() => {
			watchEffect(() => {
				if (state.isSearchOpen) {
					nextTick(() => {
						search.value.focus();
					});
				}
			});
			component.getAllFilesFromFirebase();
		});

		return { component, filteredResults, searchTerm, state, search };
	},
});
</script>
