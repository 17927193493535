import { ShowcaseProject } from "./showcase-types";

export class ShowcaseState {
	public latestProjects: Array<ShowcaseProject> = [];
	public latestProjectsBusy: boolean = false;

	public pythonProjects: Array<ShowcaseProject> = [];
	public pythonProjectsBusy: boolean = false;

	public HTMLProjects: Array<ShowcaseProject> = [];
	public HTMLProjectsBusy: boolean = false;

	public microbitProjects: Array<ShowcaseProject> = [];
	public microbitProjectsBusy: boolean = false;

	public circuitpythonProjects: Array<ShowcaseProject> = [];
	public circuitpythonProjectsBusy: boolean = false;
}