import clb from "clb";

export class EbButton {
	public variants: Function = clb({
		defaults: {
			type: "basic",
			size: "sm"
		},
		variants: {
			type: {
				basic: "inline-flex items-center shadow-sm text-base font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2",
				rounded: "flex items-center justify-center w-10 h-10 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all dark:ring-offset-gray-900"
			},
			size: {
				sm: "px-5 py-2",
				lg: "px-5 py-3",
				none: ""
			},
			color: {
				blue: "bg-gradient-to-b from-blue-300 to-blue-600 text-white ring-blue-500",
				lightBlue: "bg-blue-50 text-blue-500",
				outline: "border border-gray-300 text-gray-400 hover:text-gray-500 ring-gray-300",
				pink: "bg-gradient-to-b from-pink-300 to-blue-500 text-white ring-blue-500",
				red: "bg-gradient-to-b from-red-400 to-red-600 text-white ring-red-600",
				green: "bg-gradient-to-b from-green-300 to-green-500 text-white ring-green-500",
				gray: "border-gray-300 focus:ring-gray-300 bg-white hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-900"
			}
		}
	})
}