<template>
	<div
		class="relative border border-gray-300 rounded-md focus-within:border-blue-500 dark:border-gray-700"
		:class="`input-outline-${background}`"
	>
		<textarea
			v-if="textarea"
			placeholder=" "
			:value="modelValue"
			:readonly="readOnly"
			class="z-10 block w-full px-4 py-3 text-gray-900 bg-transparent border-transparent rounded-md appearance-none text-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:text-white"
			rows="5"
			@input="$emit('update:modelValue', $event.target.value)"
		/>
		<input
			v-else
			:type="type"
			placeholder=" "
			:min="min"
			:value="modelValue"
			:readonly="readOnly"
			class="z-10 block w-full px-4 py-3 text-gray-900 bg-transparent border-transparent rounded-md appearance-none text-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:text-white"
			@input="$emit('update:modelValue', $event.target.value)"
		>
		<label
			v-if="label"
			class="absolute top-0 z-0 px-4 py-3 text-gray-400 duration-200 pointer-events-none text-md origin-0"
		>
			{{ label }}
		</label>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbInput,
	props: {
		modelValue: {
			type: String,
		},
		label: {
			type: String,
		},
		type: {
			type: String,
		},
		background: {
			type: String,
			default: "gray"
		},
		textarea: {
			type: Boolean
		},
		min: {
			type: [Number, String, Date]
		},
		readOnly: {
			type: Boolean
		}
	},
	emits: ["update:modelValue"]
});
</script>

<style lang="postcss" scoped>
input:focus-within ~ label,
input:not(:placeholder-shown) ~ label {
  @apply transform scale-90 -translate-y-6;
}

input:focus-within ~ label {
  @apply text-blue-500 bg-gray-50;
}

.input-outline-white input:focus-within ~ label,
.input-outline-white input:not(:placeholder-shown) ~ label {
  @apply transform scale-90 -translate-y-3 z-0 ml-3 px-1 py-0 bg-white dark:bg-gray-800;
}

.input-outline-gray input:focus-within ~ label,
.input-outline-gray input:not(:placeholder-shown) ~ label {
  @apply transform scale-90 -translate-y-3 z-0 ml-3 px-1 py-0 bg-gray-50 dark:bg-gray-900;
}

textarea:focus-within ~ label,
textarea:not(:placeholder-shown) ~ label {
  @apply transform scale-90 -translate-y-6;
}

textarea:focus-within ~ label {
  @apply text-blue-500 bg-gray-50;
}

.input-outline-white textarea:focus-within ~ label,
.input-outline-white textarea:not(:placeholder-shown) ~ label {
  @apply transform scale-90 -translate-y-3 z-0 ml-3 px-1 py-0 bg-white dark:bg-gray-800;
}

.input-outline-gray textarea:focus-within ~ label,
.input-outline-gray textarea:not(:placeholder-shown) ~ label {
  @apply transform scale-90 -translate-y-3 z-0 ml-3 px-1 py-0 bg-gray-50 dark:bg-gray-900;
}
</style>
