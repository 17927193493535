import { FirebaseFile } from "@/data/providers/files-provider";

export class ProjectsState {
	public projects: Array<FirebaseFile> = [];

	public isBusy: boolean = false;

	public isImportModalOpen: boolean = false;

	public isDeleteErrorModelOpen: boolean = false;
}