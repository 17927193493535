<template>
	<div
		v-click-outside="closeDropdown"
		class="relative inline-block text-left"
	>
		<div @click="isDropdownActive = !isDropdownActive">
			<slot />
		</div>

		<transition
			enter-active-class="transition duration-100 ease-out"
			enter-from-class="transform scale-95 opacity-0"
			enter-to-class="transform scale-100 opacity-100"
			leave-active-class="transition duration-75 ease-in"
			leave-from-class="transform scale-100 opacity-100"
			leave-to-class="transform scale-95 opacity-0"
		>
			<div
				v-if="isDropdownActive && type === 'small'"
				class="absolute left-0 w-56 mt-2 origin-top-left bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
			>
				<div
					v-if="options"
					class="py-1"
				>
					<a
						v-for="option in options"
						:key="option"
						class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
						@click="option.action(); closeDropdown();"
					>
						{{ option.title }}
					</a>
				</div>
			</div>
		</transition>

		<transition
			enter-active-class="transition duration-200 ease-out"
			enter-from-class="translate-y-1 opacity-0"
			enter-to-class="translate-y-0 opacity-100"
			leave-active-class="transition duration-150 ease-in"
			leave-from-class="translate-y-0 opacity-100"
			leave-to-class="translate-y-1 opacity-0"
		>
			<div
				v-if="isDropdownActive && type === 'large'"
				class="absolute z-10 w-screen max-w-md px-2 mt-2 transform -translate-x-1/2 left-1/2 sm:px-0"
			>
				<div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
					<div
						v-if="options"
						class="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8"
					>
						<a
							v-for="option in options"
							:key="option"
							class="flex items-start p-3 -m-3 transition duration-150 ease-in-out rounded-lg cursor-pointer hover:bg-gray-50"
							@click="option.action()"
						>
							<i
								v-if="option.faIcon"
								:class="option.faIcon"
								class="flex-shrink-0 w-6 h-6 text-xl text-blue-500 fa-fw"
							/>
							<div
								v-if="option.image"
								class="flex items-center justify-center w-6 h-6"
							>
								<img
									:src="option.image"
								>
							</div>
							<div class="ml-4">
								<p class="text-base font-medium text-gray-900">
									{{ option.title }}
								</p>
								<p class="mt-1 text-sm text-gray-500">
									{{ option.description }}
								</p>
							</div>
						</a>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbDropdown,
	props: {
		options: {
			type: Array
		},
		type: {
			type: String
		}
	},
	setup() {
		const isDropdownActive: Ref<Boolean> = ref(false);

		function closeDropdown(): void {
			isDropdownActive.value = false;
		}

		return { isDropdownActive, closeDropdown };
	}
});
</script>
