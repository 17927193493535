
import { defineComponent, ref, Ref } from "vue";
import { ComponentName } from "../constants";

export default defineComponent({
	name: ComponentName.EbDropdown,
	props: {
		options: {
			type: Array
		},
		type: {
			type: String
		}
	},
	setup() {
		const isDropdownActive: Ref<Boolean> = ref(false);

		function closeDropdown(): void {
			isDropdownActive.value = false;
		}

		return { isDropdownActive, closeDropdown };
	}
});
