<template>
	<div class="flex items-center w-full h-[70.02px] bg-white border-b border-gray-200 flex-none">
		<div class="flex items-center ml-8">
			<i
				class="mr-6 text-gray-400 transition-all cursor-pointer fas fa-chevron-left hover:text-gray-500"
				@click="component.goBack()"
			/>
			<span
				class="text-gray-900 focus:outline-none focus:text-gray-700"
				contenteditable
				@input="component.onFilenameInput($event)"
			>
				{{ state.filename ? state.filename : 'Untitled Project' }}
			</span>
			<slot name="buttonsLeft" />
		</div>
		<div class="flex ml-auto divide-x divide-gray-200">
			<slot name="buttonsRight" />
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComponentName } from "../constants";
import { EbToolbar } from "./eb-toolbar";
import { state } from "@/data/providers/global-provider";

export default defineComponent({
	name: ComponentName.EbToolbar,
	setup() {
		const component: EbToolbar = new EbToolbar();

		return { component, state };
	}
});
</script>
