import { authentication } from "@/data/providers/authentication-provider";
import { getTranslatedText } from "@/data/providers/localization-provider";
import { ref, Ref } from "vue";

export const isDropdownOpen: Ref<boolean> = ref(false);

export class EbUserMenu {
	public getText(key: string): string {
		return getTranslatedText("user-menu", key);
	}

	public signOut(): void {
		isDropdownOpen.value = false;
		authentication.signOut();
	}

	public closeDropdown(): void {
		isDropdownOpen.value = false;
	}
} 